import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';

const AutoLogout = () => {
  const idleTimerRef = useRef(null);
  const sessionTimeoutPeriod = 15; // minutes

  const onIdle = () => {
    window.location.href = `${window.location.origin}/partner/login`;
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 60 * sessionTimeoutPeriod}
        onIdle={onIdle}></IdleTimer>
    </div>
  );
};

export default AutoLogout;
