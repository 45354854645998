import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextInput, Button, InlineNotification } from '@carbon/react';
import {
  validatePAEAssessmentLink,
  validatePAECandByEmailAndNRIC,
} from '../../../actions/Assessments';
import CscImage from '../../../assets/images/x0pa-brown.png';
import './CandidateSoftskillAssessment.css';
import CandRegistration from './CandRegistration';
import nric from 'nric';
import isEmail from 'validator/lib/isEmail';

import JDProcessOverlay from '../../../components/common/JDProcessOverlay';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact support@x0pa.com';

class CandidatePAEScenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currStep: 0,
      isOTPValidated: false,
      showRegistration: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const { location } = history || {};
    const { state } = location || {};
    const { xfrom } = state || {};
    const { pathname } = xfrom || {};
    const pathArray = pathname?.split('/');
    const scenarioHash = pathArray?.[pathArray?.length - 1];
    this.handleValidatePAEAssessLink(scenarioHash);
    this.setState({ scenarioHash });
  }

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at support@x0pa.com`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at support@x0pa.com';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  handleValidatePAEAssessLink = (scenarioHash) => {
    // const { match: { params: { scenarioHash } = {} } = {} } = this.props;
    this.props
      .validatePAEAssessmentLink({ scenario: 'PAE', accessCode: scenarioHash })
      .then((res) => {
        const { error, isValid, scenarioId } = res || {};
        const { message } = error || {};
        if (res && !res.error) {
          this.setState({
            showRegistration: isValid,
            scenarioId,
          });
        } else {
          this.setState({
            showRegistration: false,
            scenarioId,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    if (name === 'nric') {
      this.setState({ isNRICInvalid: !nric.validate(value) });
    }
    this.setState({
      [name]: value,
    });
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  handleValidateEmailAndNRIC = () => {
    const { candEmail, nric: nricVal, currStep } = this.state;

    const invalidObj = {};

    invalidObj.isCandEmailInvalid =
      this.isInputInValid(candEmail) || !isEmail(candEmail?.trim());

    invalidObj.isNRICInvalid = !nric.validate(nricVal);

    this.setState({ ...invalidObj }, () => {
      const areAllInputsValid = Object.values(invalidObj).every(
        (val) => val === false
      );

      if (areAllInputsValid) {
        const data = {
          email: candEmail?.trim()?.toLowerCase(),
          nric: nricVal,
        };

        this.props
          .validatePAECandByEmailAndNRIC(data)
          .then((res) => {
            const { candidateId, error } = res || {};
            const { message } = error || {};
            if (message) {
              this.handleNotification(true, 'error', message);
            } else if (candidateId) {
              const { history } = this.props;
              const { push } = history || {};
              if (push) {
                push({
                  pathname: '/public/test-summary',
                  state: { candidateId, isCandRegistered: false },
                });
              }
            } else if (
              res?.hasOwnProperty('candidateId') &&
              candidateId == null
            ) {
              this.setState({
                currStep: currStep + 1,
              });
            } else {
              this.handleErrorNotification(error);
            }
          })
          .catch((err) => {
            this.handleErrorNotification(err);
          });
      }
    });
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const {
      showRegistration,
      scenarioId,
      candEmail,
      isCandEmailInvalid,
      nric,
      isNRICInvalid,
      notifShow,
      notifKind,
      notifMessage,
      currStep,
      scenarioHash,
    } = this.state;

    return (
      <div>
        <div className="cds--row css-web-height w-100 m-0">
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
            <div>
              <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
            </div>
          </div>
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
            {showRegistration ? (
              <>
                {notifShow && (
                  <InlineNotification
                    lowContrast
                    hideCloseButton
                    className="m-0 p-0 w-100"
                    kind={notifKind}
                    title={notifMessage}
                    onCloseButtonClick={() => {
                      this.setState({ notifShow: false });
                    }}
                  />
                )}
                {currStep === 0 && (
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="my-3 d-flex justify-content-center">
                      Welcome
                    </h2>
                    <div>
                      <TextInput
                        className="input-wid"
                        type="text"
                        labelText={
                          <div>
                            Email <span className="text-danger">*</span>
                          </div>
                        }
                        required
                        name="candEmail"
                        id="candEmail"
                        value={candEmail}
                        placeholder="janedoe@gmail.com"
                        onChange={(ev) => {
                          this.handleChange(ev);
                        }}
                        invalid={isCandEmailInvalid}
                        invalidText="Please enter valid email"
                      />
                    </div>
                    <div className="mt-3">
                      <TextInput
                        required
                        id="nric"
                        name="nric"
                        labelText={
                          <div>
                            NRIC <span className="text-danger">*</span>
                          </div>
                        }
                        placeholder="T1234567A"
                        onChange={(ev) => {
                          this.handleChange(ev);
                        }}
                        value={nric}
                        invalid={isNRICInvalid}
                        invalidText="Please enter valid NRIC"
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <Button
                        onClick={this.handleValidateEmailAndNRIC}
                        className="rf-btn mt-3 css-btn-center"
                        kind="primary">
                        Validate
                      </Button>
                    </div>
                  </div>
                )}

                {currStep === 1 && (
                  <CandRegistration
                    candEmailFromPAEAuth={candEmail}
                    candNRICFromPAEAuth={nric}
                    scenarioId={scenarioId}
                    scenarioHash={scenarioHash}
                  />
                )}
              </>
            ) : (
              <div className="font-weight-bold">
                {!this.props.loading
                  ? 'Link Expired! Please contact CSC for assistance.'
                  : ''}
                <JDProcessOverlay
                  show={this.props.loading}
                  message="processing..."
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
});

const mapDispatchToProps = {
  validatePAEAssessmentLink,
  validatePAECandByEmailAndNRIC,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidatePAEScenario)
);
