import callApi from './callApi';
import { baseUrl, flaskUrl, xapiBaseUrl } from '../utils/config';
import { REQUEST_ALL_COUNTRIES } from './actionTypes';

const getAllCountries = () => (dispatch) => {
  const url = `${baseUrl}/Countries`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_COUNTRIES,
  };

  return callApi(params);
};

export { getAllCountries };
