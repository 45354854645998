import {
  REQUEST_ALL_COUNTRIES,
  SUCCESS_ALL_COUNTRIES,
  ERROR_ALL_COUNTRIES,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  countries: [],
};

const companyData = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_ALL_COUNTRIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        countries: payload,
        loading: false,
        error: null,
      };
    case ERROR_ALL_COUNTRIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default companyData;
