const publicRoutes = ['/', '/500'];

const selectedApplicationIndex = (products = []) => {
  const pathname = window.location.pathname;
  const uniqProducts = [...new Set(products)];
  const productsList = uniqProducts.map((p) => p.toLowerCase());
  let index = 1;
  if (pathname.includes('enterprise')) {
    index = productsList.indexOf('enterprise');
  } else if (pathname.includes('company')) {
    index = products.length + 2;
  } else if (pathname.includes('roboroy')) {
    index = productsList.indexOf('roboroy');
  } else if (pathname.includes('admin')) {
    index = products.length;
  } else if (pathname.includes('profile')) {
    index = products.length + 1;
  }

  return index;
};

const activeProducts = (products = [], isAdmin, companyName = '') => {
  const uniqProducts = [...new Set(products)];
  const res = uniqProducts
    .filter((p) =>
      ['enterprise', 'roboroy', 'x0pa ext'].includes(p.toLowerCase())
    )
    .map((product) => {
      let path;
      switch (product.toLowerCase()) {
        case 'enterprise':
          path = '/app/enterprise';
          break;
        case 'roboroy':
          path = '/app/roboroy';
          break;
        case 'x0pa ext':
          path = '/app/roboroy';
          break;
        default:
          path = null;
      }
      return {
        label: product,
        path,
      };
    });
  return res;
};

const isPublicRoute = (route) => {
  const hrp =
    route.includes('/invite/') ||
    route.includes('/app/roboroy/job/') ||
    route.includes('/view/') ||
    route.includes('/public/') ||
    route.includes('/pt/');
  const ipr = publicRoutes.includes(route);
  return hrp || ipr;
};

export { selectedApplicationIndex, activeProducts, isPublicRoute };
