import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import phone from 'phone';
import { TextInput, Button, Select, SelectItem } from '@carbon/react';

import Dropdown from '../../../components/common/Dropdown';
import DropDownBoxWithCreate from '../../../components/common/DropDownBoxWithCreate';
import SuccErrNotification from '../../../components/common/SuccErrNotification';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';

import nric from 'nric';

import { getAllCountries } from '../../../actions/companyData';
import {
  addAsessmentCandidate,
  getAsessmentScenarios,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  updateAsessmentCandidate,
  getAsessmentCandidateInfo,
} from '../../../actions/Assessments';

import './CandRegistration.css';

class CandRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    };
  }

  componentDidMount() {
    this.props.getAsessCompanyTypes({ ctypeIds: [1, 2] });
    // this.props.getAllCountries();
    // this.props.getAsessmentScenarios();
    const {
      isCandRegistered,
      candEmailFromPAEAuth,
      candNRICFromPAEAuth,
      scenarioId,
    } = this.props;
    if (isCandRegistered) {
      // this.handleGetCandInfo();
      this.renderOnMount();
    }

    if (scenarioId) {
      this.setState({
        emailId: this.getEmailId(candEmailFromPAEAuth),
        nric: candNRICFromPAEAuth,
      });
    }
  }

  renderOnMount = () => {
    const { candInfoRes: candInfo, assessCompanyTypes } = this.props;
    const { email, Company, Gender, contactNumber, Scenario } = candInfo || {};
    const { displayName: orgDisplayName, companyId, ctypeId } = Company || {};
    const filteredCmpTypeList = assessCompanyTypes?.filter(
      (cmpType) => cmpType?.ctypeId == ctypeId
    );
    const { ctypeName } = filteredCmpTypeList?.[0] || {};
    const { genderName, genderId } = Gender || {};
    const { displayName: scenariodisplayName, scenarioId } = Scenario || {};
    this.setState({
      ...candInfo,
      emailId: email,
      selectedOrgType: { label: ctypeName, value: ctypeId },
      selectedOrgNameValue: {
        label: orgDisplayName,
        value: companyId,
      },
      selectedScenario: {
        label: scenariodisplayName,
        value: scenarioId,
      },
      selectedGender: { label: genderName, value: genderId },
      candidatePhoneNumber: contactNumber,
      errorNotif: false,
    });
  };

  handleGetCandInfo = () => {
    const { registeredEmailId, assessCompanyTypes } = this.props;
    this.props
      .getAsessmentCandidateInfo({
        emailId: this.getEmailId(registeredEmailId),
      })
      .then((res) => {
        const { count, data } = res || {};
        let candInfo = {};
        if (data && Array.isArray(data) && data.length > 0) {
          candInfo = data?.[0];
        }
        if (res && count > 0) {
          const { email, Company, Gender, contactNumber, Scenario } =
            candInfo || {};
          const {
            displayName: orgDisplayName,
            companyId,
            ctypeId,
          } = Company || {};
          const filteredCmpTypeList = assessCompanyTypes?.filter(
            (cmpType) => cmpType?.ctypeId == ctypeId
          );
          const { ctypeName } = filteredCmpTypeList?.[0] || {};
          const { genderName, genderId } = Gender || {};
          const { displayName: scenariodisplayName, scenarioId } =
            Scenario || {};
          this.setState({
            ...candInfo,
            emailId: email,
            selectedOrgType: { label: ctypeName, value: ctypeId },
            selectedOrgNameValue: {
              label: orgDisplayName,
              value: companyId,
            },
            selectedScenario: {
              label: scenariodisplayName,
              value: scenarioId,
            },
            selectedGender: { label: genderName, value: genderId },
            candidatePhoneNumber: contactNumber,
            errorNotif: false,
          });
        } else if (res && count == 0) {
          this.setState({
            errorNotif: true,
            errMsg: 'Your email is not in the system',
          });
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    if (name === 'nric') {
      this.setState({ isNRICInvalid: !nric.validate(value) });
    }
    this.setState({
      [name]: value,
    });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        if (name === 'selectedOrgType') {
          this.setState({ selectedOrgNameValue: null });
        }
      }
    );
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  onCreateNewOrgName = (name, value) => {
    const { selectedOrgType } = this.state;
    if (value && typeof value === 'string' && value.trimStart().length === 0)
      return;
    if (value && typeof value === 'string') value = value.trimStart();
    const data = {
      companyName: value,
      ctypeId: selectedOrgType?.value,
    };
    this.setState({ isLoading: true }, () => {
      this.props
        .createAssessCompany([data])
        .then((res) => {
          if (res && !res.error) {
            const { companyId, displayName } = res?.[0] || {};
            const options = {
              label: displayName,
              value: companyId,
            };
            this.setState({
              isLoading: false,
              selectedOrgNameValue: options,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((e) => {
          console.log('Error:::::', e);
          this.setState({
            isLoading: false,
          });
        });
    });
  };

  handleOrgNameDropdownChange = (selectedOption, name, e) => {
    this.setState({
      [name]: selectedOption,
    });
  };

  isPhoneNumberInvalid = (phoneNumber, countryId) => {
    const inputPhoneNumber = phoneNumber?.toString()?.trim();
    if (inputPhoneNumber?.length == 8) {
      return false;
    }
    return true;
  };

  getEmailId = (emailId) => {
    return emailId?.toString()?.trim()?.toLowerCase();
  };

  handleRegisterCand = () => {
    const { history, isCandRegistered } = this.props || {};
    const { push } = history || {};
    const {
      nric: nricVal,
      fullName,
      firstName,
      lastName,
      selectedGender,
      emailId,
      confirmEmailId,
      countryId,
      candidatePhoneNumber,
      organisation,
      selectedOrgType,
      selectedScenario,
      selectedOrgNameValue,
      candidateId,
    } = this.state;
    const { value: scenarioId } = selectedScenario || {};
    const selectedScenarioId = scenarioId && Number(scenarioId);
    const finalEmailId = this.getEmailId(emailId);
    const finalConfirmEmailId = this.getEmailId(confirmEmailId);

    const invalidObj = {};
    if (selectedScenarioId !== 1) {
      invalidObj.isNRICInvalid = !nric.validate(nricVal);
      invalidObj.isCandidatePhoneInvalid = this.isPhoneNumberInvalid(
        candidatePhoneNumber,
        countryId
      );
    }
    invalidObj.isFullNameInvalid = this.isInputInValid(fullName);
    // invalidObj.isFirstNameInvalid = this.isInputInValid(firstName);
    // invalidObj.isLastNameInvalid = this.isInputInValid(lastName);
    invalidObj.isGenderInvalid = selectedGender?.value == null;
    invalidObj.isEmailInvalid =
      this.isInputInValid(emailId) || !isEmail(finalEmailId);
    if (!isCandRegistered) {
      invalidObj.isConfirmEmailIdInvalid = false;
      if (
        this.isInputInValid(confirmEmailId) ||
        !isEmail(finalConfirmEmailId)
      ) {
        invalidObj.isConfirmEmailIdInvalid = true;
        invalidObj.confirmEmailIdInvalidText = 'Please enter valid email';
      } else if (finalEmailId != finalConfirmEmailId) {
        invalidObj.isConfirmEmailIdInvalid = true;
        invalidObj.confirmEmailIdInvalidText = 'Email address do not match';
      }
    }
    // invalidObj.isCountryCodeInvalid = this.isInputInValid(countryId);

    // invalidObj.isOrgInvalid = this.isInputInValid(organisation);
    if (
      selectedScenarioId !== 1 &&
      selectedScenarioId !== 2 &&
      selectedScenarioId !== 3
    ) {
      invalidObj.isOrgTypeInvalid = selectedOrgType?.value == null;
      invalidObj.isOrgNameInvalid = selectedOrgNameValue?.value == null;
    }
    // invalidObj.isScenarioInvalid = selectedScenario?.value == null;

    this.setState({ ...invalidObj }, () => {
      const areAllInputsValid = Object.values(invalidObj).every(
        (val) => val === false
      );

      if (areAllInputsValid) {
        const data = {
          candidateId,
          nric: nricVal,
          fullName,
          // firstName,
          // lastName,
          gender: selectedGender?.value,
          email: this.getEmailId(emailId),
          countryCode: '65',
          contactNumber: candidatePhoneNumber,
          schoolTypeId: selectedOrgType?.value,
          schoolId: selectedOrgNameValue?.value,
          // scenarioId: selectedScenario?.value,
        };

        if (isCandRegistered) {
          this.props
            .updateAsessmentCandidate([data])
            .then((res) => {
              const { isSuccess, error } = (res && res[candidateId]) || {};
              const { message } = error || {};
              if (isSuccess) {
                if (push) {
                  push({
                    pathname: '/public/test-summary',
                    state: {
                      emailId: this.getEmailId(emailId),
                      candidateId,
                      isCandRegistered,
                    },
                  });
                }
              } else {
                this.setState({
                  errorNotif: true,
                  errMsg: message,
                });
              }
            })
            .catch((e) => {
              this.setState({
                errorNotif: true,
              });
            });
        } else {
          data['scenarioId'] = this.props.scenarioId;
          data['testCentre'] = 'X0PA_PLATFORM';
          this.props
            .addAsessmentCandidate([data])
            .then((res) => {
              const { isSuccess, error } =
                (res && res[this.getEmailId(emailId)]) || {};
              const { message } = error || {};
              if (!!isSuccess) {
                if (push) {
                  push({
                    pathname: '/public/test-summary',
                    state: {
                      emailId: this.getEmailId(emailId),
                      candidateId,
                      isCandRegistered,
                    },
                  });
                }
              } else {
                this.setState({
                  errorNotif: true,
                  errMsg: message,
                });
              }
            })
            .catch((e) => {
              this.setState({
                errorNotif: true,
              });
            });
        }
      }
    });
  };

  handleInputChange = (value) => {
    if (value && typeof value === 'string') value = value.trimStart();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      const { selectedOrgType } = this.state;
      const data = {
        activePage: 1,
        itemsPerPage: 5,
        ctypeId: selectedOrgType?.value,
        searchKey: value,
      };
      this.props.getAsessCompaniesById(data).then((res) => {
        if (res && !res.error) {
          const { data = [] } = res || {};
          const options = data.map((one) => ({
            label: one.displayName,
            value: one.companyId,
          }));
          this.setState({ orgNameArr: options });
        }
      });
    }
  };

  onInputOrgNameChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimStart();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      const { selectedOrgType } = this.state;
      const data = {
        activePage: 1,
        itemsPerPage: 5,
        ctypeId: selectedOrgType?.value,
        searchKey: value,
      };

      this.props.getAsessCompaniesById(data).then((res) => {
        if (res && !res.error) {
          const { data = [] } = res || {};
          const options = data.map((one) => ({
            label: one.displayName,
            value: one.companyId,
          }));
          cb(options);
        }
      });
      return value;
    }
  };

  render() {
    const {
      history,
      countries,
      assessScenarios,
      assessCompanyTypes,
      assessCandInfo,
      isCandRegistered,
      scenarioHash,
    } = this.props;
    const { push } = history || {};

    const {
      nric,
      isNRICInvalid = false,
      firstName,
      isFirstNameInvalid,
      lastName,
      isLastNameInvalid,
      selectedGender,
      isGenderInvalid,
      emailId,
      confirmEmailId,
      isConfirmEmailIdInvalid,
      confirmEmailIdInvalidText,
      isEmailInvalid,
      countryId,
      isCountryCodeInvalid,
      candidatePhoneNumber,
      isCandidatePhoneInvalid,
      isOrgTypeInvalid,
      selectedOrgType,
      organisation,
      isOrgInvalid,
      selectedScenario,
      isScenarioInvalid,
      selectedOrgName,
      organisationsArr = [],
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      selectedOrgNameValue,
      isOrgNameInvalid,
      orgNameArr = [],
      fullName,
      isFullNameInvalid,
    } = this.state;

    const { value: scenarioId } = selectedScenario || {};
    const selectedScenarioId = scenarioId && Number(scenarioId);

    return (
      <div>
        <div className="registraton-form mt-3">
          <div className={`${successNotif || errorNotif ? 'mb-3' : ''}`}>
            {(successNotif || errorNotif) && (
              <SuccErrNotification
                successNotif={successNotif}
                notifMsg={notifMsg}
                errorNotif={errorNotif}
                errMsg={errMsg}
                handleCloseNotifBtn={this.handleCloseNotifBtn}
              />
            )}
          </div>
          <div className="cds--tile rf-wrap">
            <div className="xpa--login">
              <div className="tab-content" id="xpaLoginTabContent">
                <div
                  className="tab-pane fade show active"
                  id="login"
                  role="tabpanel"
                  aria-labelledby="login-tab">
                  <div className="p-3">
                    <div className="cds--row mt-lg-3">
                      <div
                        className={`cds--col-sm-16 ${
                          selectedScenarioId === 1
                            ? 'cds--col-lg-16'
                            : 'cds--col-lg-8'
                        }`}>
                        <TextInput
                          required
                          disabled={!isCandRegistered}
                          name="emailId"
                          id="emailId"
                          value={emailId}
                          placeholder="jane@gmail.com"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          invalid={isEmailInvalid}
                          invalidText="Please enter valid email"
                          labelText={
                            <div>
                              Email <span className="text-danger">*</span>
                            </div>
                          }
                        />
                      </div>
                      {!isCandRegistered && (
                        <div className="cds--col-sm-16 cds--col-lg-8">
                          <TextInput
                            required
                            name="confirmEmailId"
                            id="confirmEmailId"
                            value={confirmEmailId}
                            placeholder="jane@gmail.com"
                            onChange={(ev) => {
                              this.handleChange(ev);
                            }}
                            invalid={isConfirmEmailIdInvalid}
                            invalidText={confirmEmailIdInvalidText}
                            labelText={
                              <div>
                                Confirm Email{' '}
                                <span className="text-danger">*</span>
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className=" cds--row">
                      <div
                        className={`cds--col-sm-16 ${
                          selectedScenarioId === 1
                            ? 'cds--col-lg-16'
                            : 'cds--col-lg-8'
                        }`}>
                        <TextInput
                          required
                          id="fullName"
                          name="fullName"
                          labelText={
                            <div>
                              Full Name <span className="text-danger">*</span>
                            </div>
                          }
                          placeholder="Full Name"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={fullName}
                          maxLength={200}
                          invalid={isFullNameInvalid}
                          invalidText="Please enter valid Full Name"
                        />
                      </div>
                      {/* <div className="cds--col-sm-16 cds--col-lg-8">
                        <TextInput
                          required
                          id="firstName"
                          name="firstName"
                          labelText={
                            <div>
                              First Name <span className="text-danger">*</span>
                            </div>
                          }
                          placeholder="First Name"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={firstName}
                          maxLength={15}
                          invalid={isFirstNameInvalid}
                          invalidText="Please enter valid First Name"
                        />
                      </div>
                      <div className="cds--col-sm-16 cds--col-lg-8">
                        <TextInput
                          required
                          id="lastName"
                          name="lastName"
                          labelText={
                            <div>
                              Last Name <span className="text-danger">*</span>
                            </div>
                          }
                          placeholder="Last Name"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={lastName}
                          maxLength={15}
                          invalid={isLastNameInvalid}
                          invalidText="Please enter valid Last Name"
                        />
                      </div> */}
                    </div>

                    <div className=" cds--row mt-lg-3">
                      {selectedScenarioId !== 1 && (
                        <div className="cds--col-sm-16 cds--col-lg-8">
                          <TextInput
                            disabled={!isCandRegistered}
                            required
                            id="nric"
                            name="nric"
                            labelText={
                              <div>
                                NRIC <span className="text-danger">*</span>
                              </div>
                            }
                            placeholder="T1234567A"
                            onChange={(ev) => {
                              this.handleChange(ev);
                            }}
                            value={nric}
                            invalid={isNRICInvalid}
                            invalidText="Please enter valid NRIC"
                          />
                        </div>
                      )}
                      <div
                        className={`cds--col-sm-16 ${
                          selectedScenarioId === 1
                            ? 'cds--col-lg-16'
                            : 'cds--col-lg-8'
                        }`}>
                        <div className="dropdown-margin">
                          <div className="cds--label ml-2">
                            Sex <span className="text-danger">*</span>
                          </div>
                          <Dropdown
                            id="Sex"
                            title=""
                            custom
                            mainClassName="cds--form-item mb-0"
                            className={`w-100 ${
                              isGenderInvalid ? 'invalid' : ''
                            }`}
                            titleClass="mb-2"
                            labelClass="pt-2"
                            onInputChange={() => {}}
                            options={[
                              { label: 'Male', value: 1 },
                              { label: 'Female', value: 2 },
                              { label: 'Undisclosed', value: 3 },
                            ]}
                            name="selectedGender"
                            placeholder="Sex"
                            onChange={(value, e, name) =>
                              this.handleDropdownChange(value, e, name)
                            }
                            selectedValue={selectedGender}
                          />
                          {isGenderInvalid && (
                            <div className="cds--form-requirement ml-2 mb-2">
                              Please select gender
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {selectedScenarioId !== 1 &&
                      selectedScenarioId !== 2 &&
                      selectedScenarioId !== 3 && (
                        <div className="cds--row mt-sm-2 mt-lg-3">
                          <div className="cds--col-sm-16 cds--col-lg-8">
                            <div className="dropdown-margin">
                              <div className="cds--label ml-2">
                                School/Organisation{' '}
                                <span className="text-danger">*</span>
                              </div>
                              <Dropdown
                                id="organisation"
                                title=""
                                required
                                custom
                                mainClassName="cds--form-item mb-0"
                                className={`w-100 ${
                                  isOrgTypeInvalid ? 'invalid' : ''
                                }`}
                                titleClass="mb-2"
                                labelClass="pt-2"
                                onInputChange={() => {}}
                                options={
                                  (assessCompanyTypes &&
                                    Array.isArray(assessCompanyTypes) &&
                                    assessCompanyTypes.length > 0 &&
                                    assessCompanyTypes.map((res) => {
                                      const { ctypeId, ctypeName } = res || {};
                                      return {
                                        label: ctypeName,
                                        value: ctypeId,
                                      };
                                    })) ||
                                  []
                                }
                                name="selectedOrgType"
                                placeholder="School/Organisation"
                                onChange={(value, e, name) =>
                                  this.handleDropdownChange(value, e, name)
                                }
                                selectedValue={selectedOrgType}
                              />
                              {isOrgTypeInvalid && (
                                <div className="cds--form-requirement ml-2 mb-2">
                                  Please select school/organisation
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="cds--col-sm-16 cds--col-lg-8 mt-sm-3 mt-lg-0">
                            <div className="dropdown-margin ml-2">
                              <div className="cds--label">
                                Name of School/Organisation{' '}
                                <span className="text-danger">*</span>
                              </div>
                              <Dropdown
                                id="org-name-dd"
                                custom
                                className={`w-100 ${
                                  isOrgNameInvalid ? 'invalid' : ''
                                }`}
                                title=""
                                titleClass="mb-2"
                                isAsync
                                isClearable
                                required
                                mainClassName="mb-0"
                                name="selectedOrgNameValue"
                                onInputChange={this.handleInputChange}
                                options={orgNameArr}
                                onChange={(value, e, name) =>
                                  this.handleDropdownChange(value, e, name)
                                }
                                // onInputChange={this.onInputOrgNameChange}
                                pattern="^[a-zA-Z ]*$"
                                placeholder="Start typing to see options"
                                // onChange={(value, name, e) =>
                                //   this.handleOrgNameDropdownChange(
                                //     value,
                                //     name,
                                //     e
                                //   )
                                // }
                                // newOptionCreator={(name, value) =>
                                //   this.onCreateNewOrgName(name, value)
                                // }
                                isLoading={this.state.isLoading}
                                inputProps={{
                                  maxLength: '50',
                                }}
                                selectedValue={selectedOrgNameValue}
                                disabled={selectedOrgType?.value == null}
                              />
                            </div>
                            {isOrgNameInvalid && (
                              <div className="cds--form-requirement ml-2 mb-2">
                                Please enter name of school/organisation
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {/* <div className="cds--row mt-sm-2 mt-lg-3">
                      <div className="cds--col-sm-16 cds--col-lg-8">
                        <div className="dropdown-margin">
                          <div
                            className="cds--label ml-2"
                           >
                            Scenario <span className="text-danger">*</span>
                          </div>
                          <Dropdown
                            id="scenario"
                            title=""
                            required
                            custom
                            mainClassName="cds--form-item mb-0"
                            className={`w-100 ${
                              isScenarioInvalid ? 'invalid' : ''
                            }`}
                            titleClass="mb-2"
                            labelClass="pt-2"
                            onInputChange={() => {}}
                            options={
                              assessScenarios &&
                              Array.isArray(assessScenarios) &&
                              assessScenarios.length > 0 &&
                              assessScenarios.map((res) => {
                                const { scenarioId, displayName } = res || {};
                                return {
                                  label: displayName,
                                  value: scenarioId,
                                };
                              })
                            }
                            name="selectedScenario"
                            placeholder="Scenario"
                            onChange={(value, e, name) =>
                              this.handleDropdownChange(value, e, name)
                            }
                            selectedValue={selectedScenario}
                          />
                        </div>
                      </div>
                    </div> */}

                    {selectedScenarioId !== 1 && (
                      <div className="cds--row mt-lg-3">
                        <div className="cds--col-sm-16 cds--col-lg-8">
                          <Select
                            disabled
                            className="xpa-width"
                            name="countryId"
                            labelText="Country Code"
                            id="countryId"
                            onChange={(e) => this.handleChange(e)}
                            value={countryId}
                            // invalid={
                            //   countryId?.length > 0
                            //     ? false
                            //     : isCountryCodeInvalid
                            // }
                            invalidText="Please select valid country code.">
                            <SelectItem text="Singapore (+65)" value="65" />
                            {/* <SelectItem text={'Select'} />
                        {countries &&
                          Array.isArray(countries) &&
                          countries.map((b) => (
                            <SelectItem
                              key={b.countryId}
                              text={`${b.countryFull} (${b.countryCode})`}
                              value={`${b.countryId}`}
                            />
                          ))} */}
                          </Select>
                        </div>

                        <div className="cds--col-sm-16 cds--col-lg-8">
                          <TextInput
                            labelText={
                              <div>
                                Mobile Number{' '}
                                <span className="text-danger">*</span>
                              </div>
                            }
                            name="candidatePhoneNumber"
                            id="candidatePhoneNumber"
                            placeholder="62315890"
                            type="number"
                            onChange={(e) => this.handleChange(e)}
                            value={candidatePhoneNumber}
                            invalid={isCandidatePhoneInvalid}
                            invalidText="Please enter valid number"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="cds--row align-items-center justify-content-center">
                    <Button
                      onClick={() => this.handleRegisterCand()}
                      className="rf-btn css-btn-center"
                      kind="primary">
                      Register
                    </Button>
                  </div>
                  {!isCandRegistered && (
                    <div className="ml-3 mt-2">
                      <a
                        className="ml-3"
                        href={`/public/registration/test/${scenarioHash}`}>
                        &larr; Go back
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.companyData.countries,
  loading: state.Assessments.loading,
  assessCandInfo: state.Assessments.assessCandInfo,
  assessScenarios: state.Assessments.assessScenarios,
  assessCompanyTypes: state.Assessments.assessCompanyTypes,
});

const mapDispatchToProps = {
  addAsessmentCandidate,
  getAllCountries,
  getAsessmentScenarios,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  updateAsessmentCandidate,
  getAsessmentCandidateInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandRegistration)
);
