import {
  REQUEST_GET_ASSESSMENT_SCENARIOS,
  SUCCESS_GET_ASSESSMENT_SCENARIOS,
  ERROR_GET_ASSESSMENT_SCENARIOS,
  REQUEST_ADD_ASSESSMENT_CANDIDATE,
  SUCCESS_ADD_ASSESSMENT_CANDIDATE,
  ERROR_ADD_ASSESSMENT_CANDIDATE,
  REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
  SUCCESS_UPDATE_ASSESSMENT_CANDIDATE,
  ERROR_UPDATE_ASSESSMENT_CANDIDATE,
  REQUEST_ALL_COUNTRIES,
  SUCCESS_ALL_COUNTRIES,
  ERROR_ALL_COUNTRIES,
  REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
  SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO,
  ERROR_GET_ASSESSMENT_CANDIDATE_INFO,
  REQUEST_GET_ASSESS_COMPANY_TYPES,
  SUCCESS_GET_ASSESS_COMPANY_TYPES,
  ERROR_GET_ASSESS_COMPANY_TYPES,
  REQUEST_GET_ASSESS_COMPANIES,
  SUCCESS_GET_ASSESS_COMPANIES,
  ERROR_GET_ASSESS_COMPANIES,
  REQUEST_CREATE_ASSESS_COMPANY,
  SUCCESS_CREATE_ASSESS_COMPANY,
  ERROR_CREATE_ASSESS_COMPANY,
  REQUEST_GET_ASSESSMENT_INFO_BY_UUID,
  SUCCESS_GET_ASSESSMENT_INFO_BY_UUID,
  ERROR_GET_ASSESSMENT_INFO_BY_UUID,
  REQUEST_GET_ASSESSMENT_OTP,
  SUCCESS_GET_ASSESSMENT_OTP,
  ERROR_GET_ASSESSMENT_OTP,
  REQUEST_GET_ASSESSMENT_INFO_BY_OTP,
  SUCCESS_GET_ASSESSMENT_INFO_BY_OTP,
  ERROR_GET_ASSESSMENT_INFO_BY_OTP,
  REQUEST_SAVE_CAND_RESPONSE_BY_QUES,
  SUCCESS_SAVE_CAND_RESPONSE_BY_QUES,
  ERROR_SAVE_CAND_RESPONSE_BY_QUES,
  REQUEST_SUBMIT_CAND_ASSESS_RESPONSE,
  SUCCESS_SUBMIT_CAND_ASSESS_RESPONSE,
  ERROR_SUBMIT_CAND_ASSESS_RESPONSE,
  REQUEST_VERIFY_EMAIL_SEND_OTP,
  SUCCESS_VERIFY_EMAIL_SEND_OTP,
  ERROR_VERIFY_EMAIL_SEND_OTP,
  REQUEST_VERIFY_OTP_BY_EMAIL,
  SUCCESS_VERIFY_OTP_BY_EMAIL,
  ERROR_VERIFY_OTP_BY_EMAIL,
  REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID,
  SUCCESS_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID,
  ERROR_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID,
  REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE,
  SUCCESS_SUBMIT_FEEDBACK_ASSESS_RESPONSE,
  ERROR_SUBMIT_FEEDBACK_ASSESS_RESPONSE,
  REQUEST_VALIDATE_PAE_ASSESS_LINK,
  SUCCESS_VALIDATE_PAE_ASSESS_LINK,
  ERROR_VALIDATE_PAE_ASSESS_LINK,
  REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC,
  SUCCESS_VALIDATE_PAE_CAND_BY_EMAIL_NRIC,
  ERROR_VALIDATE_PAE_CAND_BY_EMAIL_NRIC,
  REQUEST_UPLOAD_CAND_NRIC,
  SUCCESS_UPLOAD_CAND_NRIC,
  ERROR_UPLOAD_CAND_NRIC,
  REQUEST_GET_CAND_ASSESS_STATUS,
  SUCCESS_GET_CAND_ASSESS_STATUS,
  ERROR_GET_CAND_ASSESS_STATUS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  assessScenarios: [],
  assessScenarioLocations: [],
  assessCandidates: [],
  assessCandidatesCount: 0,
  countries: [],
  assessCandInfo: {},
  assessCompanyTypes: [],
};

const Assessments = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
        assessScenarios: payload?.data || [],
        assessScenarioLocations: payload?.additionalInfo?.locationValues || [],
      };
    case ERROR_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
      };
    case ERROR_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
      };
    case ERROR_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ALL_COUNTRIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        countries: payload,
        loading: false,
        error: null,
      };
    case ERROR_ALL_COUNTRIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        assessCandInfo: payload?.data?.[0] || {},
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        assessCompanyTypes: payload?.data || [],
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_GET_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_OTP:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_OTP:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_INFO_BY_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_INFO_BY_OTP:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_INFO_BY_OTP:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SAVE_CAND_RESPONSE_BY_QUES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SAVE_CAND_RESPONSE_BY_QUES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SAVE_CAND_RESPONSE_BY_QUES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SUBMIT_CAND_ASSESS_RESPONSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SUBMIT_CAND_ASSESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SUBMIT_CAND_ASSESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_VERIFY_EMAIL_SEND_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_VERIFY_EMAIL_SEND_OTP:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_VERIFY_EMAIL_SEND_OTP:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_VERIFY_OTP_BY_EMAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_VERIFY_OTP_BY_EMAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_VERIFY_OTP_BY_EMAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SUBMIT_FEEDBACK_ASSESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SUBMIT_FEEDBACK_ASSESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_VALIDATE_PAE_ASSESS_LINK:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_VALIDATE_PAE_ASSESS_LINK:
      return { ...state, loading: false, error: null };
    case ERROR_VALIDATE_PAE_ASSESS_LINK:
      return {
        ...state,
        loading: true,
        error: payload.error,
      };
    case REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_VALIDATE_PAE_CAND_BY_EMAIL_NRIC:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_VALIDATE_PAE_CAND_BY_EMAIL_NRIC:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_UPLOAD_CAND_NRIC:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_CAND_NRIC:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_CAND_NRIC:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default Assessments;
