import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

//Reducers
import companyData from './companyData';
import Assessments from './Assessments';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    companyData,
    Assessments,
  });
