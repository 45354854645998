import React from 'react';
import { InlineNotification } from '@carbon/react';

const SuccErrNotification = (props) => {
  const { notifMsg, successNotif, errorNotif, handleCloseNotifBtn, errMsg } =
    props;
  return (
    <div>
      {successNotif === true && (
        <InlineNotification
          lowContrast
          title={notifMsg}
          iconDescription="describes the close button"
          kind="success"
          onCloseButtonClick={handleCloseNotifBtn}
        />
      )}
      {errorNotif === true && (
        <InlineNotification
          lowContrast
          title={
            !errMsg
              ? 'An Error has ocurred while processing your request, Please try again later'
              : errMsg
          }
          iconDescription="describes the close button"
          kind="error"
          onCloseButtonClick={handleCloseNotifBtn}
        />
      )}
    </div>
  );
};
export default SuccErrNotification;
