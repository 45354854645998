import React from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

// import { LastLocationProvider } from "react-router-last-location";
import Routes from './routes';

import configureStore, { history } from './store';
import './App.scss';

const { store, persistor } = configureStore();

const App = (props) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Router>
          <Routes {...props} />
        </Router>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
