import React, { Fragment } from 'react';
import { Header, HeaderName, HeaderGlobalAction } from '@carbon/react';
import './Header.css';

const handleLogOut = () => {
  window.location.href = `${window.location.origin}/partner/login`;
};

const PublicHeader = (props) => {
  const { push, showLogoutBtn } = props || {};

  return (
    <Header aria-label="CSC" className="justify-content-between">
      <HeaderName
        onClick={() => {
          push && push('/');
        }}
        href="javascript:void(0)"
        prefix="">
        <h2 className="text-light font-weight-bold">PAM</h2>
      </HeaderName>
      {showLogoutBtn && (
        <div className="mr-4">
          <HeaderGlobalAction
            aria-label="Logout"
            href="/partner/login"
            onClick={() => handleLogOut()}>
            Logout
            {/* <svg
            focusable="false"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            width="16"
            height="16"
            viewBox="0 0 32 32"
            aria-hidden="true"
            style={{ fill: 'white' }}>
            <path d="M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z"></path>
            <path d="M20.586 20.586L24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586z"></path>
          </svg> */}
          </HeaderGlobalAction>
        </div>
      )}
    </Header>
  );
};

export default PublicHeader;
