import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextInput, Button, InlineNotification } from '@carbon/react';
import {
  getAsessmentInfoByUUID,
  generateAssessmentOTP,
  getAssessmentInfoByOTP,
  saveCandResponseByIndvQues,
  submitCandAssessmentResponse,
  verifyCandEmailAndSendOTP,
  verifyCandidateOTPByEmail,
  getAsessCompanyTypes,
  verifyPartnerEmailAndSendOTP,
  verifyPartnerOTPByEmail,
} from '../../../actions/Assessments';
import isEmail from 'validator/lib/isEmail';

import CscImage from '../../../assets/images/x0pa-brown.png';
import './CandidateSoftskillAssessment.css';
import CandRegistration from './CandRegistration';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact support@x0pa.com';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currStep: 0,
      isOTPValidated: false,
      showRegistration: false,
    };
  }

  componentDidMount() {
    // this component is used for candidate login & 3rd party users who want to check the assess status of the cand
    const { isPartnerLogin } = this.props || {};
    if (!isPartnerLogin) {
      this.props.getAsessCompanyTypes({ ctypeIds: [1, 2] });
    }
  }

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at support@x0pa.com`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at support@x0pa.com';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  handleChange = (e) => {
    const { name, value } = (e && e.target) || {};
    this.setState({
      [name]: value,
    });
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  handleVerifyCandEmail = (data) => {
    const { currStep } = this.state;
    this.props
      .verifyCandEmailAndSendOTP(data)
      .then((res) => {
        const { message, error } = res || {};
        const { message: errMsg } = error || {};

        if (!res) {
          throw Error(serverBusyMessage);
        }
        if (message === 'OTP email sent successfully!') {
          const emailSentMessage = 'OTP has been sent successfully!';
          this.handleNotification(true, 'success', emailSentMessage);
          this.setState({ currStep: currStep + 1 });
        } else if (errMsg) {
          this.handleNotification(true, 'error', errMsg);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  handleVerifyPartnerEmail = (data) => {
    const { currStep } = this.state;
    this.props
      .verifyPartnerEmailAndSendOTP(data)
      .then((res) => {
        const { message, error } = res || {};
        const { message: errMsg } = error || {};

        if (!res) {
          throw Error(serverBusyMessage);
        }
        if (message === 'OTP email sent successfully!') {
          const emailSentMessage = 'OTP has been sent successfully!';
          this.handleNotification(true, 'success', emailSentMessage);
          this.setState({ currStep: currStep + 1 });
        } else if (errMsg) {
          this.handleNotification(true, 'error', errMsg);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  handleVerifyEmailAndSendOtp = () => {
    const { isPartnerLogin } = this.props;
    const { userEmailId } = this.state;
    const isUserEmailInvalid =
      this.isInputInValid(userEmailId) || !isEmail(userEmailId?.trim());
    this.setState({ isUserEmailInvalid }, () => {
      if (!isUserEmailInvalid) {
        const data = { email: userEmailId?.toLowerCase()?.trim() };

        if (isPartnerLogin) {
          this.handleVerifyPartnerEmail(data);
        } else {
          this.handleVerifyCandEmail(data);
        }
      }
    });
  };

  getEmailId = (emailId) => {
    return emailId?.toString()?.trim()?.toLowerCase();
  };

  handleVerifyCandOTP = (data) => {
    const { history } = this.props;
    const { push } = history || {};

    this.props
      .verifyCandidateOTPByEmail(data)
      .then((res) => {
        const { data, error } = res || {};
        const { message: errMsg } = error || {};

        if (!res) {
          throw Error(serverBusyMessage);
        }
        if (data && Array.isArray(data) && data.length > 0) {
          const candInfoRes = data?.[0];
          const { Scenario, email, candidateId } = candInfoRes || {};
          const { scenarioId } = Scenario || {};
          if (scenarioId == 4) {
            push({
              pathname: '/public/test-summary',
              state: {
                emailId: this.getEmailId(email),
                candidateId,
                isCandRegistered: true,
              },
            });
          } else {
            this.setState({
              isOTPValidated: true,
              candInfoRes,
            });
          }
        } else if (errMsg) {
          this.handleNotification(true, 'error', errMsg);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  handleVerifyPartnerOTP = (data) => {
    const { history } = this.props;
    const { push } = history || {};
    const { email } = data || {};
    this.props
      .verifyPartnerOTPByEmail(data)
      .then((res) => {
        const { response, error } = res || {};
        const { message: errMsg } = error || {};
        const { message: succMsg } = response || {};

        if (!res) {
          throw Error(serverBusyMessage);
        }
        if (succMsg === 'Validation successful!') {
          push({
            pathname: '/partner/dashboard',
            state: {
              partnerEmailId: email,
            },
          });
        } else if (errMsg) {
          this.handleNotification(true, 'error', errMsg);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  handleValidateOTP = () => {
    const { isPartnerLogin } = this.props;
    const { userEmailId = '', otp = '' } = this.state;
    const data = {
      email: userEmailId?.toString()?.trim()?.toLowerCase(),
      otp: otp?.trim(),
    };
    if (isPartnerLogin) {
      this.handleVerifyPartnerOTP(data);
    } else {
      this.handleVerifyCandOTP(data);
    }
  };

  render() {
    const { history, isPartnerLogin } = this.props;
    const { push } = history || {};
    const {
      notifShow,
      notifKind,
      notifMessage,
      isOTPValidated,
      userEmailId,
      isUserEmailInvalid,
      currStep,
    } = this.state;

    return (
      <div>
        <div className="cds--row css-web-height w-100 m-0">
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
            <div>
              <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
            </div>
          </div>
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
            {!isOTPValidated && (
              <>
                {notifShow && (
                  <InlineNotification
                    lowContrast
                    hideCloseButton
                    className="m-0 p-0 w-100"
                    kind={notifKind}
                    title={notifMessage}
                    onCloseButtonClick={() => {
                      this.setState({ notifShow: false });
                    }}
                  />
                )}

                {currStep === 0 && (
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="my-3 d-flex justify-content-center">
                      Welcome
                    </h2>
                    <TextInput
                      className="input-wid"
                      type="text"
                      labelText={
                        <div>
                          Email <span className="text-danger">*</span>
                        </div>
                      }
                      required
                      name="userEmailId"
                      id="userEmailId"
                      value={userEmailId}
                      placeholder="janedoe@gmail.com"
                      onChange={(ev) => {
                        this.handleChange(ev);
                      }}
                      invalid={isUserEmailInvalid}
                      invalidText="Please enter valid email"
                    />

                    <div className="d-flex align-items-center">
                      <Button
                        onClick={this.handleVerifyEmailAndSendOtp}
                        className="mt-3 rf-btn css-btn-center"
                        kind="primary">
                        Send OTP
                      </Button>
                    </div>
                  </div>
                )}

                {currStep === 1 && (
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="my-3 d-flex justify-content-center">
                      Welcome
                    </h2>
                    <TextInput
                      className="input-wid"
                      name="otp"
                      onChange={this.handleChange}
                      placeholder="VG1DE7U3"
                      maxLength={12}
                      labelText={
                        <div>
                          OTP <span className="text-danger">*</span>
                        </div>
                      }
                    />

                    <div className="d-flex align-items-center">
                      <Button
                        onClick={this.handleValidateOTP}
                        className="mt-3 rf-btn css-btn-center"
                        kind="primary">
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
                <JDProcessOverlay
                  show={this.props.loading}
                  message="processing..."
                />
              </>
            )}

            {isOTPValidated && (
              <CandRegistration
                registeredEmailId={userEmailId}
                isCandRegistered={true}
                candInfoRes={this.state.candInfoRes}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
  generateAssessmentOTP,
  getAssessmentInfoByOTP,
  saveCandResponseByIndvQues,
  submitCandAssessmentResponse,
  verifyCandEmailAndSendOTP,
  verifyCandidateOTPByEmail,
  getAsessCompanyTypes,
  verifyPartnerEmailAndSendOTP,
  verifyPartnerOTPByEmail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
