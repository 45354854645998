import callApi from './callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_ASSESSMENT_SCENARIOS,
  REQUEST_ADD_ASSESSMENT_CANDIDATE,
  REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
  REQUEST_ALL_COUNTRIES,
  REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
  REQUEST_GET_ASSESS_COMPANY_TYPES,
  REQUEST_GET_ASSESS_COMPANIES,
  REQUEST_CREATE_ASSESS_COMPANY,
  REQUEST_GET_ASSESSMENT_INFO_BY_UUID,
  REQUEST_GET_ASSESSMENT_OTP,
  REQUEST_GET_ASSESSMENT_INFO_BY_OTP,
  REQUEST_SAVE_CAND_RESPONSE_BY_QUES,
  REQUEST_SUBMIT_CAND_ASSESS_RESPONSE,
  REQUEST_VERIFY_EMAIL_SEND_OTP,
  REQUEST_VERIFY_OTP_BY_EMAIL,
  REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID,
  REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE,
  REQUEST_VALIDATE_PAE_ASSESS_LINK,
  REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC,
  REQUEST_UPLOAD_CAND_NRIC,
  REQUEST_GET_CAND_ASSESS_STATUS,
} from './actionTypes';

const getAsessmentScenarios = (year) => (dispatch) => {
  const url = `${baseUrl}/scenario?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESSMENT_SCENARIOS,
  };
  return callApi(params);
};

const getAsessmentCandidateInfo =
  ({ candidateId, emailId }) =>
  (dispatch) => {
    const url = `${baseUrl}/candidate/single?_=${new Date().getTime()}`;

    const data = {};
    if (candidateId) {
      data['candidateIds'] = [candidateId];
    }
    if (emailId) {
      data['emails'] = [emailId];
    }

    const params = {
      headers: {
        'content-type': 'application/json',
        _scene_: 'PAE',
        _code_: new Date().getTime(),
      },
      dispatch,
      method: 'POST',
      url,
      body: JSON.stringify(data),
      actionType: REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
    };
    return callApi(params);
  };

const addAsessmentCandidate = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/candidate`;
  const params = {
    dispatch,
    actionType: REQUEST_ADD_ASSESSMENT_CANDIDATE,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      _scene_: 'PAE',
      _code_: new Date().getTime(),
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const updateAsessmentCandidate = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/candidate`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAllCountries = () => (dispatch) => {
  const url = `${baseUrl}/Countries`;

  const params = {
    dispatch,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    url,
    actionType: REQUEST_ALL_COUNTRIES,
  };

  return callApi(params);
};

const getAsessCompanyTypes =
  ({ ctypeIds }) =>
  (dispatch) => {
    let url = `${baseUrl}/company2/types?_=${new Date().getTime()}`;
    if (ctypeIds && Array.isArray(ctypeIds) && ctypeIds.length > 0) {
      url += `&ctypeIds=[${ctypeIds}]`;
    }
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_GET_ASSESS_COMPANY_TYPES,
    };
    return callApi(params);
  };

const getAsessCompaniesById = (data) => (dispatch) => {
  const { activePage, itemsPerPage, searchKey, ctypeId } = data || {};
  if (!ctypeId) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/company2?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (ctypeId) {
    url += `&ctypeId=${ctypeId}`;
  }
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESS_COMPANIES,
  };
  return callApi(params);
};

const createAssessCompany = (data) => (dispatch) => {
  const url = `${baseUrl}/company2?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_ASSESS_COMPANY,
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  };
  return callApi(params);
};

const getAsessmentInfoByUUID = (publicUuid) => (dispatch) => {
  if (!publicUuid) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/assessment/public/qns/pt/${publicUuid}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESSMENT_INFO_BY_UUID,
  };
  return callApi(params);
};

const generateAssessmentOTP = (publicUuid) => (dispatch) => {
  if (!publicUuid) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/softskill/otp/generate?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_GET_ASSESSMENT_OTP,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify({ publicUuid: [publicUuid] }),
  };

  return callApi(params);
};

const getAssessmentInfoByOTP = (data) => (dispatch) => {
  const { publicUuid } = data || {};
  if (!publicUuid) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/softskill/otp/validate`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_GET_ASSESSMENT_INFO_BY_OTP,
  };
  return callApi(params);
};

const saveCandResponseByIndvQues = (data) => (dispatch) => {
  let url = `${baseUrl}/softskill/save`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_SAVE_CAND_RESPONSE_BY_QUES,
  };
  return callApi(params);
};

const submitCandAssessmentResponse = (data) => (dispatch) => {
  const { softskillAssessmentId } = data || {};
  if (!softskillAssessmentId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/softskill/save/final`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_SUBMIT_CAND_ASSESS_RESPONSE,
  };
  return callApi(params);
};

const verifyCandEmailAndSendOTP = (data) => (dispatch) => {
  const { email } = data || {};
  if (!email) {
    const error = {
      msg: 'Invalid email passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/candidate/single/otp`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VERIFY_EMAIL_SEND_OTP,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const verifyCandidateOTPByEmail = (data) => (dispatch) => {
  const { otp } = data || {};
  if (!otp) {
    const error = {
      msg: 'Invalid otp passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/candidate/single/validate`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VERIFY_OTP_BY_EMAIL,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const verifyPartnerEmailAndSendOTP = (data) => (dispatch) => {
  const { email } = data || {};
  if (!email) {
    const error = {
      msg: 'Invalid email passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/thirdPartyStatusChecker/send-otp`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VERIFY_EMAIL_SEND_OTP,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const verifyPartnerOTPByEmail = (data) => (dispatch) => {
  const { otp } = data || {};
  if (!otp) {
    const error = {
      msg: 'Invalid otp passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/thirdPartyStatusChecker/verify-otp`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VERIFY_OTP_BY_EMAIL,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const getFeedbackAssessInfoById = (id) => (dispatch) => {
  if (!id) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/assessmentfeedback/${id}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID,
  };
  return callApi(params);
};

const submitFeedbackAssessResponse = (data) => (dispatch) => {
  const { assessmentFeedbackId } = data || {};
  if (!assessmentFeedbackId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/assessmentfeedback/response/${assessmentFeedbackId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE,
  };
  return callApi(params);
};

const validatePAEAssessmentLink = (data) => (dispatch) => {
  const { accessCode } = data || {};
  if (!accessCode) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/scenario/access-code/validate`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VALIDATE_PAE_ASSESS_LINK,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const validatePAECandByEmailAndNRIC = (data) => (dispatch) => {
  const url = `${baseUrl}/candidate/validate`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC,
    dispatch,
    url,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return callApi(params);
};

const uploadCandNrics = (data) => (dispatch) => {
  const url = `${baseUrl}/thirdPartyStatusChecker/upload-nrics?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CAND_NRIC,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

const getCandAsessStatus = (data) => (dispatch) => {
  const { isExport } = data || {};
  let url = `${baseUrl}/thirdPartyStatusChecker/check-status`;
  if (isExport) {
    url += `?isExport=${isExport}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_GET_CAND_ASSESS_STATUS,
  };
  return callApi(params);
};

const getCandAsessStatusReportByUuid = (exportUuid) => (dispatch) => {
  if (!exportUuid) {
    return Promise.reject(new Error('Invalid Export Id'));
  }
  let url = `${baseUrl}/commonHelper/pollingStatus/3rd-party-data-exports`;
  if (exportUuid) {
    url += `/${exportUuid}`;
  }
  url += `?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_CAND_ASSESS_STATUS_BY_UUID',
  };
  return callApi(params);
};

export {
  getAsessmentScenarios,
  addAsessmentCandidate,
  updateAsessmentCandidate,
  getAllCountries,
  getAsessmentCandidateInfo,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  getAsessmentInfoByUUID,
  generateAssessmentOTP,
  getAssessmentInfoByOTP,
  saveCandResponseByIndvQues,
  submitCandAssessmentResponse,
  verifyCandEmailAndSendOTP,
  verifyCandidateOTPByEmail,
  getFeedbackAssessInfoById,
  submitFeedbackAssessResponse,
  validatePAEAssessmentLink,
  validatePAECandByEmailAndNRIC,
  verifyPartnerEmailAndSendOTP,
  verifyPartnerOTPByEmail,
  uploadCandNrics,
  getCandAsessStatus,
  getCandAsessStatusReportByUuid,
};
